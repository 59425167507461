import { MIXPANEL_PROXY_SERVICE_BASEURL, http } from 'config';
import { createServiceEndpointUrlFactory } from 'services/utils';
import { MIXPANEL_FORWARD_EVENT_PATH } from './constants';
import { ForwardedMixpanelEvent } from './types';

const createEndpointUrlFactory = createServiceEndpointUrlFactory(
  MIXPANEL_PROXY_SERVICE_BASEURL,
);

export function forwardMixpanelEvent(
  data: ForwardedMixpanelEvent,
): Promise<void> {
  const url = forwardMixpanelEvent.url();
  return http().post(url, { data });
}

forwardMixpanelEvent.url = createEndpointUrlFactory(
  MIXPANEL_FORWARD_EVENT_PATH,
);
