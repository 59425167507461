import {
  GoogleDriveAudioFile,
  GoogleDriveAudioFileDetail,
  GoogleDriveFile,
  GoogleDriveFileDetail,
  GoogleDriveVideoFile,
  GoogleDriveVideoFileDetail,
} from './types';

export function isGoogleDriveVideoFile(
  file: GoogleDriveFile,
): file is GoogleDriveVideoFile {
  return file.mimeType.startsWith('video');
}

export function isGoogleDriveAudioFile(
  file: GoogleDriveFile,
): file is GoogleDriveAudioFile {
  return file.mimeType.startsWith('audio');
}

export function isGoogleDriveVideoFileDetail(
  file: GoogleDriveFileDetail,
): file is GoogleDriveVideoFileDetail {
  return file.mimeType.startsWith('video');
}

export function isGoogleDriveAudioFileDetail(
  file: GoogleDriveFileDetail,
): file is GoogleDriveAudioFileDetail {
  return file.mimeType.startsWith('audio');
}
