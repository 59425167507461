import { getEnvironmentConfigValue } from 'config';
import { EnvironmentConfig } from 'types';
import { CreateEndpointUrl, PathVariables } from './types';

export type PathVariableMapping = Record<string, string | number>;

export function replacePathVariables<TPath extends string>(
  path: TPath,
  variables: PathVariables<TPath>,
): string {
  return Object.keys(variables).reduce((currentPath, variable) => {
    const pathVariable = `:${variable}`;
    const value = variables[variable as keyof PathVariables<TPath>];
    const newPath = currentPath.replace(pathVariable, String(value));

    if (newPath === currentPath) {
      throw new Error(
        `Error replacing variable in path "${path}".  Could not find pattern "${pathVariable}"`,
      );
    }

    return newPath;
  }, path);
}

export const createServiceEndpointUrlFactory =
  (serviceConfig: EnvironmentConfig) =>
  <TPath extends string>(path: TPath) =>
    ((vars) => {
      // NB: this line cannot be moved outside of this function since
      // createServiceEndpointUrlFactory run when the file is imported, which could
      // be before the library is configured.  Putting the line here means it will
      // be executed during runtime after the library has been configured.
      const baseUrl = getEnvironmentConfigValue(serviceConfig);
      const replacedPath = !vars ? path : replacePathVariables(path, vars);
      return baseUrl + replacedPath;
    }) as CreateEndpointUrl<TPath>;
