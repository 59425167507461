import { getEnvironment } from 'config/configure';
import { Environment } from 'types';

function getFallbackEnvironment(env: Environment): Environment | undefined {
  if (env === 'local') {
    return 'dev';
  }

  if (env === 'dev') {
    return 'prod';
  }

  return undefined;
}

export function getEnvironmentConfigValue<T>(
  config: Partial<Record<Environment, T>>,
  env: Environment = getEnvironment(),
): T {
  const val = config[env];

  if (val !== undefined) {
    return val;
  }

  const fallbackEnv = getFallbackEnvironment(env);

  if (fallbackEnv) {
    return getEnvironmentConfigValue(config, fallbackEnv);
  }

  throw new Error(`Could not find "${env}" environment variable`);
}
