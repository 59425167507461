import { MEDIA_UPLOAD_SERVICE_BASEURL, http } from 'config';
import { createServiceEndpointUrlFactory } from 'services/utils';
import { YOUTUBE_INFO_PATH } from './constants';
import { GetYoutubeVideoInfoArgs, YoutubeInfoResult } from './types';

const createEndpointUrlFactory = createServiceEndpointUrlFactory(
  MEDIA_UPLOAD_SERVICE_BASEURL,
);

/**
 * Retrieve YouTube Video Information
 *
 * Takes YouTube url and returns various information about the YouTube video.
 */
export function getYoutubeVideoInfo(args: GetYoutubeVideoInfoArgs) {
  const { url: youtubeUrl } = args;

  const url = getYoutubeVideoInfo.url();

  return http().get<YoutubeInfoResult>(url, {
    params: {
      url: youtubeUrl,
    },
  });
}

getYoutubeVideoInfo.url = createEndpointUrlFactory(YOUTUBE_INFO_PATH);
