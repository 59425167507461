import superagent, { SuperAgent, SuperAgentRequest } from 'superagent';
import {
  HttpDeleteConfig,
  HttpGetConfig,
  HttpHeadConfig,
  HttpPostConfig,
  ResponseHeaders,
  RestClient,
} from 'types';
import { defaultToUndefined } from 'utils/lang';
import authHeaderRequestPlugin from './authHeaderRequestPlugin';

export default class SuperAgentClient implements RestClient {
  private request: SuperAgent<SuperAgentRequest>;

  constructor() {
    this.request = superagent.agent().use(authHeaderRequestPlugin());
  }

  async get<R>(url: string, config?: HttpGetConfig): Promise<R> {
    const { params = {}, withCredentials } = config ?? {};

    const req = this.request.get(url).query(params).authHeader(config);

    if (withCredentials) {
      req.withCredentials();
    }

    const { body } = await req;

    return body;
  }

  async post<R, D extends object | undefined = undefined>(
    url: string,
    config: HttpPostConfig<D>,
  ): Promise<R> {
    const { data: payload = {}, params = {} } = config;

    const { body } = await this.request
      .post(url)
      .query(params)
      .send(payload)
      .authHeader(config);

    return body;
  }

  async delete<R>(url: string, config: HttpDeleteConfig = {}): Promise<R> {
    const { params = {} } = config;

    const { body } = await this.request
      .delete(url)
      .query(params)
      .authHeader(config);

    return body;
  }

  async head(
    url: string,
    config: HttpHeadConfig = {},
  ): Promise<ResponseHeaders> {
    const { params = {} } = config;
    const result = await this.request
      .head(url)
      .query(params)
      .authHeader(config);

    const headers = {
      contentLength: defaultToUndefined(result.header['content-length']),
    };

    return headers;
  }
}
