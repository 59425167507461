import { Environment, RestClient } from 'types';
import { SpareminApiSdkConfiguration, SpareminTokenProvider } from './types';

let configured = false;
let environment: Environment;
let client: RestClient;
let getSpareminToken: SpareminTokenProvider;

export function getEnvironment(): Environment {
  if (!environment || !configured) {
    throw new Error(
      'No environment set. Run configure() before using @sparemin/api-sdk',
    );
  }

  return environment;
}

export function http(): RestClient {
  if (!client || !configured) {
    throw new Error(
      'HTTP client not configured.  Run configure() before using @sparemin/api-sdk',
    );
  }

  return client;
}

export function getTokenProvider(): SpareminTokenProvider {
  if (!getSpareminToken || !configured) {
    throw new Error(
      'getSpareminToken not configured.  Run configure() before using @sparemin/api-sdk',
    );
  }

  return getSpareminToken;
}

export function configure(config: SpareminApiSdkConfiguration) {
  if (process.env.NODE_ENV !== 'test' && configured) {
    throw new Error(
      '@sparemin/api-sdk was configured multiple times, which can cause ' +
        'errors if the library is configured with different values. Make sure to ' +
        'only call configure() once.',
    );
  }

  environment = config.environment;
  client = config.client;
  getSpareminToken = config.getSpareminToken;

  configured = true;
}
