const PODCAST_V1_PATH = '/api/v1/podcast';

export const PODCAST_SEARCH_PATH = `${PODCAST_V1_PATH}/search`;
export const EPISODE_SEARCH_PATH = `${PODCAST_V1_PATH}/episode/search`;
export const FEED_SEARCH_PATH = `${PODCAST_V1_PATH}/custom-url`;

const PODCASTS_PATH = `${PODCAST_V1_PATH}/podcasts`;
export const PODCAST_DETAIL_PATH = `${PODCASTS_PATH}/:podcastId`;

export const FAVORITE_PODCASTS_PATH = `${PODCASTS_PATH}/favorites`;
export const FAVORITE_PODCAST_PATH = `${FAVORITE_PODCASTS_PATH}/:podcastId`;

export const REFRESH_PODCAST_PATH = `${PODCASTS_PATH}/refresh-job`;
export const REFRESH_PODCAST_JOB_STATUS_PATH = `${REFRESH_PODCAST_PATH}/:refreshJobId`;

export const OWNED_PODCASTS_PATH = `${PODCASTS_PATH}/ownership`;
