const THIRD_PARTY_AUTH_V1_PATH = '/api/v1/third-party-auth';

const THIRD_PARTY_AUTH_PATH = `${THIRD_PARTY_AUTH_V1_PATH}/social-auth`;
export const MY_THIRD_PARTY_AUTH_PATH = `${THIRD_PARTY_AUTH_PATH}/me`;

export const ZOOM_PATH = `${THIRD_PARTY_AUTH_V1_PATH}/zoom`;
export const MY_ZOOM_MEETINGS_PATH = `${ZOOM_PATH}/me/meeting`;
export const MY_ZOOM_RECORDINGS_PATH = `${ZOOM_PATH}/me/recordings`;
export const MY_ZOOM_RECORDING_DETAIL_PATH = `${MY_ZOOM_MEETINGS_PATH}/:meetingId/recording-file/:recordingFileId`;

export const GOOGLE_DRIVE_PATH = `${THIRD_PARTY_AUTH_V1_PATH}/google-drive`;
export const MY_GOOGLE_DRIVE_FILES_PATH = `${GOOGLE_DRIVE_PATH}/me/get-files`;
export const MY_GOOGLE_DRIVE_FILE_DETAIL_PATH = `${GOOGLE_DRIVE_PATH}/me/get-file-detail/:fileId`;
