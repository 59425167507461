import { EnvironmentConfig } from 'types';

export const PODCAST_SERVICE_BASEURL: EnvironmentConfig = {
  dev: 'https://podcast-service-dev1.sparemin.com',
  prod: 'https://podcast-service.sparemin.com',
};

export const AUDIO_PROXY_SERVICE_BASEURL: EnvironmentConfig = {
  dev: 'https://audio-proxy-service-dev1.sparemin.com',
  prod: 'https://audio-proxy-service.sparemin.com',
};

export const MEDIA_UPLOAD_SERVICE_BASEURL: EnvironmentConfig = {
  dev: 'https://media-upload-service-dev1.sparemin.com',
  prod: 'https://media-upload-service.sparemin.com',
};

export const MIXPANEL_PROXY_SERVICE_BASEURL: EnvironmentConfig = {
  dev: 'https://mp-service-dev1.headliner.app',
  prod: 'https://mp-service.headliner.app',
};

export const API_BASEURL: EnvironmentConfig = {
  dev: 'https://api-dev1.headliner.link',
  prod: 'https://api.headliner.link',
};

export const THIRD_PARTY_AUTH_SERVICE_BASEURL: EnvironmentConfig = {
  dev: 'https://third-party-authentication-service-dev1.headliner.app',
  prod: 'https://third-party-authentication-service.headliner.app',
};
