import { PODCAST_SERVICE_BASEURL, http } from 'config';
import { createServiceEndpointUrlFactory } from 'services/utils';
import {
  EPISODE_SEARCH_PATH,
  FAVORITE_PODCASTS_PATH,
  FAVORITE_PODCAST_PATH,
  FEED_SEARCH_PATH,
  OWNED_PODCASTS_PATH,
  PODCAST_DETAIL_PATH,
  PODCAST_SEARCH_PATH,
  REFRESH_PODCAST_JOB_STATUS_PATH,
  REFRESH_PODCAST_PATH,
} from './constants';
import {
  AddFavoritePodcastArgs,
  GetPodcastDetailArgs,
  Podcast,
  PodcastDetail,
  PodcastSearchArgs,
  PodcastSearchResults,
  PodcastEpisodeSearchArgs,
  PodcastEpisodeSearchResults,
  FavoritePodcastsResults,
  RemoveFavoritePodcastArgs,
  RefreshPodcastArgs,
  RefreshPodcastResult,
  GetPodcastRefreshStatusArgs,
  GetPodcastRefreshStatusResult,
  GetOwnedPodcastsResult,
} from './types';

const createEndpointUrlFactory = createServiceEndpointUrlFactory(
  PODCAST_SERVICE_BASEURL,
);

export function searchForPodcasts(
  args: PodcastSearchArgs,
): Promise<PodcastSearchResults> {
  const url = searchForPodcasts.url();
  return http().get(url, { params: args });
}

searchForPodcasts.url = createEndpointUrlFactory(PODCAST_SEARCH_PATH);

export function searchForPodcastEpisodes(
  args: PodcastEpisodeSearchArgs,
): Promise<PodcastEpisodeSearchResults> {
  const url = searchForPodcastEpisodes.url();
  return http().get(url, { params: args });
}

searchForPodcastEpisodes.url = createEndpointUrlFactory(EPISODE_SEARCH_PATH);

/**
 * When a podcast cannot be found for the given rssUrl (or if there's any other
 * kind of error, e.g. the rssUrl is not a URL) the backend throws a 500 error
 */
export async function searchForPodcastFeed(rssUrl: string): Promise<Podcast> {
  const url = searchForPodcastFeed.url();
  return http().get(url, {
    params: {
      url: rssUrl,
    },
  });
}

searchForPodcastFeed.url = createEndpointUrlFactory(FEED_SEARCH_PATH);

export async function getFavoritePodcasts(): Promise<FavoritePodcastsResults> {
  const url = getFavoritePodcasts.url();
  return http().get(url);
}

getFavoritePodcasts.url = createEndpointUrlFactory(FAVORITE_PODCASTS_PATH);

export async function addFavoritePodcast(
  args: AddFavoritePodcastArgs,
): Promise<void> {
  const { podcastId, ...requestPayload } = args;
  const url = addFavoritePodcast.url({ podcastId });

  await http().post(url, { data: requestPayload });
}

addFavoritePodcast.url = createEndpointUrlFactory(FAVORITE_PODCAST_PATH);

export async function removeFavoritePodcast(
  args: RemoveFavoritePodcastArgs,
): Promise<void> {
  const { podcastId } = args;
  const url = removeFavoritePodcast.url({ podcastId });

  await http().delete(url);
}

removeFavoritePodcast.url = createEndpointUrlFactory(FAVORITE_PODCAST_PATH);

export async function getPodcastDetail(
  args: GetPodcastDetailArgs,
): Promise<PodcastDetail> {
  const { podcastId, ...params } = args;
  const url = getPodcastDetail.url({ podcastId });

  return http().get(url, { params });
}

getPodcastDetail.url = createEndpointUrlFactory(PODCAST_DETAIL_PATH);

export async function refreshPodcast(
  args: RefreshPodcastArgs,
): Promise<RefreshPodcastResult> {
  const { podcastFeedId, podcastId } = args;
  const url = refreshPodcast.url();

  return http().post(url, { data: { podcastFeedId, podcastId } });
}

refreshPodcast.url = createEndpointUrlFactory(REFRESH_PODCAST_PATH);

export async function getPodcastRefreshStatus(
  args: GetPodcastRefreshStatusArgs,
): Promise<GetPodcastRefreshStatusResult> {
  const url = getPodcastRefreshStatus.url(args);

  return http().get(url);
}

getPodcastRefreshStatus.url = createEndpointUrlFactory(
  REFRESH_PODCAST_JOB_STATUS_PATH,
);

export async function getOwnedPodcasts(): Promise<GetOwnedPodcastsResult> {
  const url = getOwnedPodcasts.url();

  return http().get(url);
}

getOwnedPodcasts.url = createEndpointUrlFactory(OWNED_PODCASTS_PATH);
