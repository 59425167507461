import { SuperAgentRequest } from 'superagent';
import { getTokenProvider } from 'config';
import { createAuthHeader } from 'token';
import { RequestConfig } from 'types';

const DEFAULT_CONFIG: RequestConfig = {
  disableAuthHeader: false,
  omitBearerPrefix: false,
};

export default function authHeaderRequestPlugin() {
  return (request: SuperAgentRequest): SuperAgentRequest => {
    const getToken = getTokenProvider();
    const token = getToken();

    const setAuthHeader = (config?: RequestConfig) => {
      const { disableAuthHeader = false, omitBearerPrefix = false } =
        config ?? {};

      if (disableAuthHeader || !token) {
        request.unset('Authorization');
        return request;
      }

      request.set('Authorization', createAuthHeader(token, !omitBearerPrefix));

      return request;
    };

    request.authHeader = setAuthHeader;

    return setAuthHeader(DEFAULT_CONFIG);
  };
}
