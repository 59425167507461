import { http, THIRD_PARTY_AUTH_SERVICE_BASEURL } from 'config';
import { createServiceEndpointUrlFactory } from 'services/utils';
import {
  MY_GOOGLE_DRIVE_FILE_DETAIL_PATH,
  MY_GOOGLE_DRIVE_FILES_PATH,
  MY_THIRD_PARTY_AUTH_PATH,
  MY_ZOOM_RECORDING_DETAIL_PATH,
  MY_ZOOM_RECORDINGS_PATH,
} from './constants';
import {
  GetGoogleDriveFileDetailArgs,
  GetGoogleDriveFilesArgs,
  GetZoomRecordingArgs,
  GetZoomRecordingDetailArgs,
  GoogleDriveFileDetail,
  GoogleDriveFilesResult,
  ThirdPartyAuthRequest,
  ThirdPartyToken,
  ZoomRecordingDetail,
  ZoomRecordingsResult,
} from './types';

const createEndpointUrlFactory = createServiceEndpointUrlFactory(
  THIRD_PARTY_AUTH_SERVICE_BASEURL,
);

/**
 * Retrieve third party social auth for a given user.
 *
 * This endpoint tries to use the refresh token to get an id token and access
 * token, which will be cached
 */
export function getMyThirdPartyAuth(
  args: ThirdPartyAuthRequest,
): Promise<ThirdPartyToken> {
  const { forceLogin, isMobileAuth, provider, providerUserId, scopeContext } =
    args;

  const url = getMyThirdPartyAuth.url();

  return http().get(url, {
    params: {
      // if forceLogin isn't explicitly set to false, API returns
      // "404: no refresh tokens found"
      forceLogin: !!forceLogin,
      isMobileAuth,
      provider,
      providerUserId,
      scopeContext,
    },
    withCredentials: true,
  });
}

getMyThirdPartyAuth.url = createEndpointUrlFactory(MY_THIRD_PARTY_AUTH_PATH);

/**
 * Obtain the recording list associated with the user from Zoom
 *
 * The response retains the meeting/recording structure from Zoom. All recordings
 * are found under a meeting. It is up to the client to figure out how to display
 * the recordings under the meetings. Recording for a meeting can be empty since
 * we post process and filter out any recordings that doesn't meet the condition
 * for being playable (unsupported media or status is not ready).
 */
export function getMyZoomRecordings(
  args: GetZoomRecordingArgs,
): Promise<ZoomRecordingsResult> {
  const { accessToken, mediaType, pageSize, pageToken, providerUserId } = args;

  const url = getMyZoomRecordings.url();
  return http().post(url, {
    data: {
      accessToken,
      providerUserId,
    },
    params: {
      mediaType,
      pageSize,
      pageToken,
    },
  });
}

getMyZoomRecordings.url = createEndpointUrlFactory(MY_ZOOM_RECORDINGS_PATH);

/**
 * Obtain the meeting's recording detail
 */
export function getMyZoomRecordingDetail(
  args: GetZoomRecordingDetailArgs,
): Promise<ZoomRecordingDetail> {
  const { accessToken, meetingId, providerUserId, recordingFileId } = args;

  const url = getMyZoomRecordingDetail.url({ meetingId, recordingFileId });

  return http().post(url, {
    data: {
      accessToken,
      providerUserId,
    },
  });
}

getMyZoomRecordingDetail.url = createEndpointUrlFactory(
  MY_ZOOM_RECORDING_DETAIL_PATH,
);

/**
 * Obtain the file list associated with the user from Google Drive
 *
 * Automatically filters for downloadable audio and video files. The result is
 * not expected to match the input pageSize. This is due to the fact that Google
 * Drive api does not support filter by downloable assets. So we do some postprocessing
 * to filter out assets that are not downloadable. It's possible that you can get zero
 * results even if a pageSize of a non-zero value was requested.
 */
export function getMyGoogleDriveFiles(
  args: GetGoogleDriveFilesArgs,
): Promise<GoogleDriveFilesResult> {
  const { accessToken, mediaType, pageSize, pageToken, providerUserId } = args;

  const url = getMyGoogleDriveFiles.url();

  return http().post(url, {
    data: {
      accessToken,
      providerUserId,
    },
    params: {
      mediaType,
      pageSize,
      pageToken,
    },
  });
}

getMyGoogleDriveFiles.url = createEndpointUrlFactory(
  MY_GOOGLE_DRIVE_FILES_PATH,
);

/**
 * Obtain the file detail
 */
export function getMyGoogleDriveFileDetail(
  args: GetGoogleDriveFileDetailArgs,
): Promise<GoogleDriveFileDetail> {
  const { accessToken, fileId, providerUserId } = args;

  const url = getMyGoogleDriveFileDetail.url({ fileId });

  return http().post(url, {
    data: {
      accessToken,
      providerUserId,
    },
  });
}

getMyGoogleDriveFileDetail.url = createEndpointUrlFactory(
  MY_GOOGLE_DRIVE_FILE_DETAIL_PATH,
);
