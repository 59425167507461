import { API_BASEURL, http } from 'config';
import { createServiceEndpointUrlFactory } from 'services/utils';
import { RECEIVE_BEACON_EVENT_PATH } from './constants';
import { BeaconEvent } from './types';

const createEndpointUrlFactory = createServiceEndpointUrlFactory(API_BASEURL);

export async function sendBeaconEvent(event: BeaconEvent): Promise<void> {
  const url = sendBeaconEvent.url();
  await http().post(url, { data: event });
}

sendBeaconEvent.url = createEndpointUrlFactory(RECEIVE_BEACON_EVENT_PATH);
