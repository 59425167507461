import * as yup from 'yup';
import { secToMillis } from 'utils/time';
import { SPAREMIN_JWT_ISSUER, SPAREMIN_JWT_ROLES } from './constants';
import {
  RawJwt,
  ShouldRenewTokenOptions,
  SpareminJwt,
  SpareminJwtIssuer,
  SpareminJwtRole,
} from './types';

const spareminJwtSchema: yup.Schema<SpareminJwt> = yup.object().shape({
  exp: yup.number().min(0).required(),
  iat: yup.number().min(0).required(),
  iss: yup.mixed<SpareminJwtIssuer>().oneOf([SPAREMIN_JWT_ISSUER]).required(),
  role: yup.mixed<SpareminJwtRole>().oneOf(SPAREMIN_JWT_ROLES).required(),
  sub: yup.number().min(0).required(),
});

function jwtDecode(token: string) {
  const payload = token.split('.')[1];

  // see https://stackoverflow.com/a/70851350
  // atob in node has been deprecated but atob in the browser environment has not.
  // accessing atob on window avoids tsc telling us it's deprecated
  return JSON.parse(window.atob(payload));
}

function calculateDefaultRefreshThresholdMillis(token: SpareminJwt): number {
  const durationSec = token.exp - token.iat;

  // refresh when token is within 10% of its expiration date.  this mimics what we
  // do in most apps by default which is refresh when the 30-day token is within 3
  // days of expiring
  return secToMillis(durationSec * 0.1);
}

/**
 * Decodes and validates a spareminToken, returning a parsed token object.
 *
 * This function will not throw an error if the token is expired, only if it
 * is invalid (unexpected issuer, role, negative value for user id, etc)
 */
export function decodeSpareminToken(token: string): SpareminJwt {
  try {
    const rawToken: RawJwt = jwtDecode(token);

    const parsedToken = {
      ...rawToken,
      sub: parseInt(rawToken.sub, 10),
    };

    return spareminJwtSchema.validateSync(parsedToken);
  } catch {
    throw new Error(`invalid sparemin token: "${token}"`);
  }
}

export function isExpired(token: string) {
  const { exp } = decodeSpareminToken(token);
  return Date.now() >= secToMillis(exp);
}

/**
 * returns true if the sparemin token should be renewed.
 *
 * When `opts.thresholdMillis` is passed, this function returns true if the
 * `currentTime + thresholdMillis > tokenExp`, otherwise this function returns true
 * when the token has elapsed 90% of its lifetime (as defined by its expiration
 * time - issued time).
 */
export function shouldRenewToken(
  token: string,
  opts?: ShouldRenewTokenOptions,
) {
  const decodedToken = decodeSpareminToken(token);

  const thresholdMillis =
    opts?.thresholdMillis ??
    calculateDefaultRefreshThresholdMillis(decodedToken);

  return (
    new Date().getTime() + thresholdMillis >= secToMillis(decodedToken.exp)
  );
}

/**
 * Takes a value from the http authentication header and returns the sparemin token
 */
export function parseAuthHeader(
  headerValue: string | number | boolean | undefined,
): string | undefined {
  if (!headerValue || typeof headerValue !== 'string') {
    return undefined;
  }

  return headerValue.match(/(Bearer )?(.+)/)?.[2];
}

/**
 * Takes a sparemin token and creates an authentication header.
 *
 * Some of the sparemin services require the 'Bearer` prefix, while others don't.
 * If `includeBearerPrefix` is `true`, then the auth header will include the
 * bearer prefix.
 */
export function createAuthHeader(token: string, includeBearerPrefix = true) {
  return `${includeBearerPrefix ? 'Bearer ' : ''}${token}`;
}

/**
 * Takes an auth header value and a new token and replaces the token in the
 * header with the new one.
 */
export function replaceHeaderToken(
  headerValue: string | number | boolean,
  newToken: string,
) {
  if (!headerValue || typeof headerValue !== 'string') {
    return headerValue;
  }

  return headerValue.replace(/(Bearer )?(.+)/, `$1${newToken}`);
}
