import qs from 'query-string';
import { AUDIO_PROXY_SERVICE_BASEURL, http } from 'config';
import { createServiceEndpointUrlFactory } from 'services/utils';
import { AUDIO_PROXY_PATH } from './constants';

const createEndpointUrlFactory = createServiceEndpointUrlFactory(
  AUDIO_PROXY_SERVICE_BASEURL,
);

export async function getExternalMediaSize(srcUrl: string) {
  const apiUrl = getExternalMediaSize.url();

  const remoteUrl = qs.stringifyUrl({
    url: srcUrl,
    // https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req/856948#856948
    query: { 'x-request': 'xhr' },
  });

  const headers = await http().head(apiUrl, {
    disableAuthHeader: true,
    params: {
      url: remoteUrl,
    },
  });

  return parseFloat(headers.contentLength ?? '');
}

getExternalMediaSize.url = createEndpointUrlFactory(AUDIO_PROXY_PATH);
